import React from 'react';
import Helmet from 'react-helmet';
import { APP_NAME, isLive } from '../../constants';
import Banner from '../Banner';
import Header from '../Header';
import Content from './Content';
import './privacy.scss';

class PrivacyPolicy extends React.Component {
    componentDidMount() {
        window.scrollTo(0, 0)
    }
    render() {
        return (<div id="privacyWrapper">
            <Helmet>
                <title>Privacy Policy - {APP_NAME}</title>
                {isLive && <meta name="description" content="Osrit Trucking Software committed to protecting your privacy when using our software. Read our full privacy policy here and contact our support team today!" />}
            </Helmet>

            <Banner />
            <Header />
            <Content />
        </div>)
    }
}

export default PrivacyPolicy;
