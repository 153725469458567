const BASE_PATH = '';
const AppRoutes = {
    home: `${BASE_PATH}/`,
    login: `${BASE_PATH}/login`,
    reset: `${BASE_PATH}/reset`,
    signup: `${BASE_PATH}/signup`,
    register: `${BASE_PATH}/register`,
    contact: `${BASE_PATH}/contact`,
    verified: `${BASE_PATH}/verified`,
    faq: `${BASE_PATH}/faq`,
    dispatch: `${BASE_PATH}/dispatch`,
    dashcam: `${BASE_PATH}/dashcam`,
    equipments: `${BASE_PATH}/equipments`,
    pricing: `${BASE_PATH}/pricing`,
    gps: `${BASE_PATH}/gps`,
    eld: `${BASE_PATH}/eld`,
    terms: `${BASE_PATH}/terms`,
    termsDownload: `${BASE_PATH}/terms-download`,
    policy: `${BASE_PATH}/policy`,
    apps: `${BASE_PATH}/apps`,
    blog: `blog.osrit.com`,

    carrier: `${BASE_PATH}/carrier`,
    brokerage: `${BASE_PATH}/brokerage`,
    recruiting: `${BASE_PATH}/recruiting`,
    accounting: `${BASE_PATH}/accounting`,
    tracking: `${BASE_PATH}/tracking`,
    compliance: `${BASE_PATH}/compliance`,
    contactUs: `${BASE_PATH}/contact-us`,
    allApps: `${BASE_PATH}/all-apps`,

    demo: "https://calendly.com/osrit/30min"
}
export default AppRoutes;
