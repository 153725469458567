import React from "react";
import { Collapse, Navbar, NavbarToggler, Nav, NavItem } from "reactstrap";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Link } from "react-router-dom";
import AppRoutes from "../../AppRoutes";
// import logo from "../../images/logo.png";
import { goTo } from "../../actions/appActions";
import "./header.scss";

class Header extends React.Component {
    constructor(props) {
        super(props);

        this.toggle = this.toggle.bind(this);
        this.state = {
            isOpen: false
        };
    }
    toggle() {
        this.setState({
            isOpen: !this.state.isOpen
        });
    }

    goHome = () => {
        this.props.goTo("/");
    };
    render() {
        const { curr } = this.props;
        const isHome = curr === "home";
        const isDispatch = curr === "dispatch";
        const isDashcam = curr === "dashcam";
        const isPrice = curr === "pricing";
        const isGps = curr === "gps";
        const isEld = curr === "eld";

        const activeStyles = {
            borderBottom: "5px solid #009ddd"
        };

        const inActiveStyles = {};
        return (
            <Navbar id="homeHeader" color="light" light={true} expand="md">
                <Link to={AppRoutes.home} className="navbar-brand">
                    <img
                        src={"/logo.png"}
                        alt="Osrit"
                        style={{
                            height: 48,
                            marginLeft: 16
                        }}
                    />
                </Link>
                <NavbarToggler onClick={this.toggle} />
                <Collapse isOpen={this.state.isOpen} navbar={true}>
                    <Nav className="ml-auto" navbar={true}>
                        <NavItem >
                            <Link
                                style={isHome ? activeStyles : inActiveStyles}
                                to={AppRoutes.home}
                                className="home_header_link text-uppercase"
                            >
                                {"Home"}
                            </Link>
                        </NavItem>
                        <NavItem                        >
                            <Link
                                style={isDispatch ? activeStyles : inActiveStyles}
                                to={AppRoutes.dispatch}
                                className="home_header_link text-uppercase"
                            >
                                {"Dispatch"}
                            </Link>
                        </NavItem>
                        <NavItem>
                            <a href='https://www.truckdashcamera.com/'
                                style={isDashcam ? activeStyles : inActiveStyles}
                                // to={AppRoutes.dashcam}
                                className="home_header_link text-uppercase"
                            >
                                {"Dashcam"}
                            </a>
                        </NavItem>
                        <NavItem                        >
                            <Link
                                style={isPrice ? activeStyles : inActiveStyles}
                                to={AppRoutes.pricing}
                                className="home_header_link text-uppercase"
                            >
                                {"Pricing"}
                            </Link>
                        </NavItem>
                        <NavItem >
                            <Link
                                style={isGps ? activeStyles : inActiveStyles} to={AppRoutes.gps}
                                className="home_header_link text-uppercase"
                            >
                                {"Gps"}
                            </Link>
                        </NavItem>
                        <NavItem >
                            <Link
                                style={isEld ? activeStyles : inActiveStyles} to={AppRoutes.eld}
                                className="home_header_link text-uppercase"
                            >
                                {"Eld"}
                            </Link>
                        </NavItem>
                        <NavItem>
                            <a href={"//" + AppRoutes.blog}
                                rel="noopener noreferrer"
                                className="home_header_link text-uppercase">{'Blogs'}</a>
                        </NavItem>

                        {/*<NavItem>
                            <a
                                href="https://eldbooks.com/"
                                rel="noopener noreferrer"
                                target="_blank"
                                className="home_header_link text-uppercase"
                            >
                                {"Eld"}
                            </a>
                        </NavItem>*/}
                        <NavItem>
                            <Link
                                to={AppRoutes.login}
                                className="home_header_link text-uppercase active"
                            >
                                {"Log In"}
                            </Link>
                        </NavItem>
                    </Nav>
                </Collapse>
            </Navbar>
        );
    }
}

const mapStateToProps = state => ({});
const mapDispatchToProps = dispatch =>
    bindActionCreators(
        {
            goTo
        },
        dispatch
    );

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Header);
